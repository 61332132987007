@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  border: 1px solid lightgray;
}

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

@layer utilities {
  .scrollbar-xs::-webkit-scrollbar {
    width: 5px;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #dedede;
  border-radius: 15px;
}

.triangle {
  display: none;
}

@supports (clip-path: inset(50%)) {
  .triangle-top-left {
    display: block;
    height: 16px;
    width: 16px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    top: -8px;
    left: 16px;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(135deg);
    border-radius: 0 0 0 3px;
  }

  .triangle-top-right {
    display: block;
    height: 16px;
    width: 16px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    top: -8px;
    right: 16px;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(135deg);
    border-radius: 0 0 0 3px;
  }

  .triangle-top-center {
    display: block;
    height: 16px;
    width: 16px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%) rotate(135deg);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    border-radius: 0 0 0 3px;
  }

  .triangle-bottom-center {
    display: block;
    height: 16px;
    width: 16px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    border-radius: 0 0 3px 0;
  }

  .triangle-right-top {
    display: block;
    height: 16px;
    width: 16px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    top: 16px;
    right: -8px;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-135deg);
    border-radius: 0 0 0 3px;
  }
}

.custom-handle {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #1153aa;
  opacity: 0.75;
  border-radius: 4px;
}
.custom-handle-sw {
  bottom: -4px;
  left: -4px;
  cursor: sw-resize;
}
.custom-handle-se {
  bottom: -4px;
  right: -4px;
  cursor: se-resize;
}
.custom-handle-nw {
  top: -4px;
  left: -4px;
  cursor: nw-resize;
}
.custom-handle-ne {
  top: -4px;
  right: -4px;
  cursor: ne-resize;
}
.custom-handle-w,
.custom-handle-e {
  top: 50%;
  margin-top: -4px;
  cursor: ew-resize;
}
.custom-handle-w {
  left: -4px;
}
.custom-handle-e {
  right: -4px;
}
.custom-handle-n,
.custom-handle-s {
  left: 50%;
  margin-left: -4px;
  cursor: ns-resize;
}
.custom-handle-n {
  top: -4px;
}
.custom-handle-s {
  bottom: -4px;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
